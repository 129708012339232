import React, { useCallback, useMemo } from "react";
import _get from "lodash/get";

interface IFormikInput {
  formik: any;
  name: string;
  className?: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
  pattern?: any;
  async?: boolean;
  id?: string;
  kind?: string;
  label?: string;
  type?: string;
  required?: boolean;
  src?: string;
}

const inputKind = {
  default: (value: string) => value,
  account: (value: string) => (!value ? "" : String(value).replace(/\D/g, "")),
  money: (value: string) =>
    !value
      ? "$0"
      : new Intl.NumberFormat("es-ES", {
          style: "currency",
          currency: "EUR",
        }).format(parseInt(value)),
};

const outputKind = {
  default: (value: string) => value,
  account: (value: string) => String(value).replace(/\D/g, ""),
  money: (value: string) => String(value).replace(/\D/g, ""),
};

export default function FormikInput({
  formik,
  name = "",
  label,
  type = "text",
  kind = "default",
  className = "",
  placeholder = "",
  maxLength = undefined,
  disabled = false,
  async = false,
  required = false,
  src = "",
}: IFormikInput) {
  const value = _get(formik.values, name, "");
  const parsedValue = _get(inputKind, "kind", (value: any) => value)(value);
  const error = _get(formik.errors, name, "");
  const isTouched = _get(formik.touched, name, false);

  const onBlur = formik.handleBlur;

  const onChange = useCallback(
    (e) => {
      formik.setFieldValue(
        name,
        _get(outputKind, kind, (_: any) => {})(e.target.value)
      );
    },
    [formik, kind, name]
  );

  return useMemo(
    () => (
      <div className="form-group">
        {label && (
          <label>
            {label} {required ? "*" : ""}
          </label>
        )}
        <input
          className={`form-control${className ? " " + className : ""}`}
          disabled={disabled}
          maxLength={maxLength}
          name={name}
          placeholder={placeholder}
          type={type}
          value={parsedValue}
          onBlur={onBlur}
          onChange={onChange}
          src={src}
          {...formik.getFieldProps(name)}
        />
        <div className="icon-container">
          <div
            className="icon"
            style={{ backgroundImage: `url(${src})` }}
          ></div>
        </div>
        {async && error && <p className="at-error">{error}</p>}
        {!async && error && isTouched && <p className="at-error">{error}</p>}
        
        <style jsx>{`
            .form-group {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin-bottom: 10px;
 
              span {
                margin: 10px 0 10px;
                color: #3A4065;
              }
            }
        `}</style>
      </div>
    ),
    [
      name,
      label,
      parsedValue,
      type,
      onBlur,
      onChange,
      className,
      placeholder,
      maxLength,
      disabled,
      error,
      async,
      isTouched,
      required,
      src
    ]
  );
}


// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import AuthLayout from "@layouts/auth.layout";
import { authService } from "@services/auth";
import { authActions } from "@store/auth/auth";
import { useDispatch } from "react-redux";
import { notify } from "@utils/toast.utils";
import { useAuth } from "@hooks/useAuth";
// import { setCookie } from "@utils/cookie.utils";
import { useFormik } from "formik";
import FormikInput from "@components/formik/FormikInput";
import useTranslation from "next-translate/useTranslation";
import * as yup from "yup";
import _get from "lodash/get";

export default function Login() {
  const router = useRouter();
  const { t } = useTranslation("common");
  const [resend, setResend] = useState(false);
  const [email, setEmail] = useState("");
  const [authError, setAuthError] = useState("");
  const dispatch = useDispatch();
  const { login } = useAuth();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      username: yup
        .string()
        // .matches(
        //   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        //   t("login.validations.email")
        // )
        .required(t("login.validations.required")),
      password: yup.string().required(t("login.validations.required")),
    }),
    onSubmit: async (values: any) => {

      try {
        const { data } = await authService.login(values);
        if (data.status === "resetPasswordRequired") {
          setAuthError(data.message);
        }
        if (data.status === "blocked") {
          return dispatch(authActions.error(data.message));
        }
        if (data.status === "verified") {
          dispatch(authActions.error(t("messages.notVerified")));
          if (data.data.resend) {
            setResend(true);
            setEmail(data.data.user.email);
          }
        } else {
          dispatch(authActions.login(data.data));
          login(data.data.access_token, data.data.user);
          notify("success", t("messages.welcomeBack"), { icon: false, theme: 'colored' });
          router.push("/");
        }
      } catch (err) {
        if (_get(err, "response.data.statusCode", false) === 401) {
          if (_get(err, "response.data.message", false) === "Unauthorized") {
            setAuthError(t("login.validations.incorrect"));
          } else {
            setResend(true);
            setAuthError(t("login.validations.notVerified"));
          }
        }
      }
    },
  });

  const resendVerificationEmail = async () => {

    try {
      await authService.resendEmailVerification({ "username": formik.values.username });
      setResend(false);
      notify("success", t("messages.resent"), { theme: 'colored' });
    } catch (err) {
      notify("error", t("messages.somethingWentWrong"), { theme: 'colored' });
    }
  };

  return (
    <AuthLayout
      title={t("login.seo.title")}
      description={t("login.seo.description")}
    >
      <div className="login-page">
        <div className="login__container">

          <div className="login__box">
            <div className="login__container__form">
              {authError !== "" && (
                <div className="incorrect-pass">
                  <span>{authError}</span>
                </div>
              )}
              <Link href="/">
                <a className={"login__container-logo"}>
                  {/*// @ts-ignore */}
                  <Image
                    src={"/assets/images/stardeos02.png"}
                    alt="logo"
                    quality={100}
                    layout="fill"
                    objectFit="contain"
                  />
                </a>
              </Link>
              <form className="at-themeform" onSubmit={formik.handleSubmit}>
                <fieldset>
                  <FormikInput
                    formik={formik}
                    name="username"
                    placeholder={t("login.email/username")}
                    src="/assets/images/input-icons/at-symbol.svg"
                  />
                  <FormikInput
                    formik={formik}
                    name="password"
                    type="password"
                    placeholder={t("login.password")}
                    src="/assets/images/input-icons/lock-closed.svg"
                  />
                  <div className="links" style={{ flexDirection: 'column', alignItems: 'flex-end' }} >
                    <Link href="/forget-password">
                      <a className="link primary">{t("login.link1")}</a>
                    </Link>
                    <Link href="/forget-username">
                      <a className="link primary">{t("login.link3")}</a>
                    </Link>
                  </div>
                  <div className="form-group mt-5 d-flex">
                    {formik.isSubmitting ? (
                      <button type="button" className="button third">
                        <i className="fas fa-circle-notch fa-spin"></i>
                      </button>
                    ) : (
                      <React.Fragment>
                        <button type="submit"
                         style={{ justifyContent: "center", alignItems: "center", display: "flex"}} 
                         className="button third"
                        >
                          {t("login.button")} <img src="/assets/images/input-icons/arrow-right.svg" style={{marginLeft: "8px", width: "20px", height: "20px"}}/>
                        </button>
                        {resend && (
                          <button
                            type="button"
                            className="button third"
                            style={{ justifyContent: "center", alignItems: "center", display: "flex"}}
                            onClick={resendVerificationEmail}
                          >
                            {t("login.resendButton")} <img src="/assets/images/input-icons/arrow-right.svg" style={{marginLeft: "8px", width: "20px", height: "20px"}}/>
                          </button>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                </fieldset>
              </form>
              <div className="login__container__information">
                <p>
                  {t("login.dontHaveAccount")}&nbsp;
                  <Link href="/signup">
                    <a className="link primary">{t("login.link2")}</a>
                  </Link>
                </p>
                <div className="img-logo"></div>
              </div>
            </div>
          </div>
        </div>

        <style jsx global>{`

          body {
            background-color: #020410;
          }

          .login-page {
            min-height: 100vh !important;
            display: flex;
            align-items: center;
            justify-content: center;
            background-position-x: center;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url("/assets/images/bg.jpg");
            background-size: cover;

            * {
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                Helvetica, Arial, sans-serif, "Apple Color Emoji",
                "Segoe UI Emoji", "Segoe UI Symbol";
            }

            .header {
              width: 100%;
              padding: 10px 50px;
              position: absolute;
              top: 0px;
            }

            .login {
              &__container {
                align-items: center;
                width: 100%;
                max-width: 450px;
                width: 100%;

                @media screen and (min-height: 750px) {
                  position: relative;
                  top: -50px;
                }

                @media screen and (max-width: 450px) {
                  width: 90%;
                }

                &-logo {
                  text-align: center;
                  display: block;
                  width: 180px;
                  height: 145px;
                  position: relative;
                  margin: 0 auto;
                  margin-bottom: 30px;
                }

                @media screen and (max-width: 970px) {
                  display: block;
                }
                
                .icon-container {
                  position: absolute;
                  top: 50%;
                  right: 12px;
                  transform: translateY(-50%);
                  width: 20px;
                  height: 20px;
                }
                
                .icon {
                  width: 100%;
                  height: 100%;
                  background-size: cover;
                  opacity: 0.4;
                }

                .login__box {
                  background-color: #0E1121;
                  border-radius: 8px;
                  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
                    0 2px 4px -1px rgba(0, 0, 0, 0.06);
                  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
                    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                }

                .form-group {
                  margin-bottom: 24px;
                  position: relative;

                  .at-error {
                    top: 43px;
                    position: absolute;
                    font-size: 11px !important;
                  }
                }

                &__information {
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding: 0px;
                  text-align: center;

                  @media screen and (max-width: 1020px) {
                    padding: 0px 25px;
                  }

                  h2 {
                    color: white;
                    font-weight: 400;
                    font-size: 45px;
                    width: 460px;
                    line-height: 55px;
                    margin: 0px;
                    margin-bottom: 10px;

                    @media screen and (max-width: 970px) {
                      width: 100%;
                    }

                    @media screen and (max-width: 450px) {
                      font-size: 35px;
                      line-height: 40px;
                    }
                  }

                  p {
                    font-size: 13px;
                    color: #E7F3F3;

                    @media screen and (max-width: 400px) {
                      font-size: 10px;
                    }
                  }

                  a {
                    font-weight: 500;
                  }
                }

                &__form {
                  padding: 45px 50px 30px;

                  @media screen and (max-width: 450px) {
                    padding: 45px 20px 30px;
                  }

                  h3 {
                    text-align: center;
                    font-weight: 400;
                    margin-bottom: 30px;
                    font-size: 18px;
                    color: #57606f;
                  }

                  fieldset {
                    border: none;
                  }

                  .form-group {
                    margin-bottom: 20px;
                  }

                  .links {
                    display: flex;
                    justify-content: flex-end;
                    position: relative;

                    a {
                      font-size: 13px;
                      font-weight: 400;
                      margin-top: 5px;

                      &:focus {
                        outline: none;
                      }
                    }
                  }

                  .form-group {
                    label {
                      font-size: 14px;
                      margin-bottom: 10px;
                      display: block;
                      color: #394562;
                      font-weight: 400;
                    }

                    input {
                      width: 100%;
                      height: 45px;
                      border-radius: 4px;
                      border: 1px solid hsla(231, 34%, 15%, 1);
                      padding: 0px 10px;
                      letter-spacing: 0.3px;
                      background-color: #020410;
                      font-size: 14px;
                      font-weight: 400;
                      color: hsla(176, 34%, 93%, 1);
                    }

                    button {
                      width: 100%;
                      height: 45px;
                      border: none;
                      border-radius: 4px;
                      color: white;
                      font-size: 14px;
                      margin-top: 20px;
                      cursor: pointer;
                    }

                    .at-error {
                      margin: 0px;
                      font-size: 12px;
                      margin-top: 4px;
                      margin-left: 4px;
                      color: #ff7676;
                    }
                  }
                }
              }
            }

            h2 {
              color: red;
            }

            .at-logoarea {
              display: flex;
              justify-content: center;
              margin: 40px 0px;
            }

            .at-content {
              max-width: 700px;
              width: 100%;
              text-align: center;
              background: #fff;
              margin: 0 auto;
              padding: 50px 20px;
              border-radius: 5px;
            }

            .button {
              i {
                font-family: "Font Awesome 5 Free";
              }
            }
          }
          .incorrect-pass {
            width: 100%;
            padding: 10px 20px;
            background-color: #f8d7da;
            border: #f5c6cb solid 1.5px;
            border-radius: 4px;
            margin-bottom: 10px;
            span {
              color: #721c24;
              font-size: 14px;
            }
          }
        `}</style>
      </div>
    </AuthLayout>
  );
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  